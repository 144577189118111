
import Api from "@/assets/api/api";
import { useStore } from "vuex";
import Operate from "@/components/Operate/index.vue";
import getClassName from "@/utils/getClassName";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
} from "vue";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "",
  components: { Operate },
  setup() {
    const store = useStore();
    const data = reactive({
      tableObj: {},
      rightList: <any>[
        {
          id: 1,
          name: "当前班次",
          value: "",
        },
        {
          id: 2,
          name: "操作员",
          value: "",
        },
      ],
      leftList: [
        {
          id: 1,
          name: "系统状态:",
          typeName: "运行中",
          color: "green",
        },
        {
          id: 1,
          name: "产线模式:",
          typeName: "正常模式",
          name2: "工件模式:",
          typeName2: "A工件",
        },
      ],
      AllData: {},
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      getByPage();
      LnfoAlert();
    });

    const getByPage = () => {
      Api.commonPost(
        "dap",
        "byPage",
        {
          pageId: 100002,
          projectId: store.state.projectId,
        },
        (res: any) => {
          data.AllData = res.data;
          data.rightList[0].name =
            res.data.pageTabs[0].dataValues[0].dataItemValues[4].title;
          data.rightList[0].value =
            res.data.pageTabs[0].dataValues[0].dataItemValues[4].value;
          data.rightList[1].name =
            res.data.pageTabs[0].dataValues[0].dataItemValues[3].title;
          data.rightList[1].value =
            res.data.pageTabs[0].dataValues[0].dataItemValues[3].value;

          data.leftList[0].name =
            res.data.pageTabs[0].dataValues[0].dataItemValues[0].title;
          data.leftList[0].typeName =
            res.data.pageTabs[0].dataValues[0].dataItemValues[0].value;

          data.leftList[1].name =
            res.data.pageTabs[0].dataValues[0].dataItemValues[1].title;
          data.leftList[1].typeName =
            res.data.pageTabs[0].dataValues[0].dataItemValues[1].value;
          data.leftList[1].name2 =
            res.data.pageTabs[0].dataValues[0].dataItemValues[2].title;
          data.leftList[1].typeName2 =
            res.data.pageTabs[0].dataValues[0].dataItemValues[2].value;
          data.leftList[0].color = getClassName(
            res.data.pageTabs[0].dataValues[0].dataItemValues[0].value
          );
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };
    const LnfoAlert = () => {
      Api.commonPost(
        "dap",
        "LnfoAlert",
        {
          companyId: store.state.companyId,
          projectId: store.state.projectId,
        },
        (res: any) => {
          data.tableObj = res.data;
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };
    return {
      ...toRefs(data),
    };
  },
});
