import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f45f3b3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Operate_box" }
const _hoisted_2 = { class: "row al-c ju-s" }
const _hoisted_3 = { class: "Border" }
const _hoisted_4 = { class: "row al-c" }
const _hoisted_5 = { class: "title_top" }
const _hoisted_6 = { class: "title_top" }
const _hoisted_7 = { class: "Border" }
const _hoisted_8 = { class: "row ju-s" }
const _hoisted_9 = { class: "row al-c item" }
const _hoisted_10 = { class: "title_top" }
const _hoisted_11 = { class: "row al-c item" }
const _hoisted_12 = { class: "title_top" }
const _hoisted_13 = { class: "title_top" }
const _hoisted_14 = { class: "btn_box Border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Operate = _resolveComponent("Operate")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rightList, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(item.name) + ":", 1),
                _createElementVNode("p", _hoisted_6, _toDisplayString(item.value), 1)
              ])
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leftList, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id
            }, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("p", _hoisted_10, _toDisplayString(item.name) + ":", 1),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", {
                      style: _normalizeStyle({ color: item.color }),
                      class: "title_top"
                    }, _toDisplayString(item.typeName), 5)
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("p", _hoisted_12, _toDisplayString(item.name2), 1),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", _hoisted_13, _toDisplayString(item.typeName2), 1)
                  ])
                ])
              ])
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", null, [
          _createVNode(_component_Operate, { tableObj: _ctx.tableObj }, null, 8, ["tableObj"])
        ])
      ])
    ])
  ]))
}